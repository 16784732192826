<template>
  <div
    :class="[
      'cd__actions-wrapper sticky-main',
      { 'pre-login': !isAuthenticated }
    ]"
  >
    <div
      class="cd__action-div"
      :class="
        getSelectedCourse.intro_video_url ? 'cd__video-url' : 'cd__video-no-url'
      "
    >
      <!-- video preview -->
      <div
        class="intro_video_url d-none d-lg-block"
        v-if="getSelectedCourse.intro_video_url"
        :style="{ 'background-image': 'url(' + youtubeThumbnail + ')' }"
      >
        <img
          src="@/assets/images/icons/play-blue.svg"
          alt="play"
          width="auto"
          height="auto"
          class="c-pointer"
          @click="$bvModal.show('intro-video-modal')"
        />
      </div>

      <!-- call to action -->
      <div class="cd__course-card">
        <div class="cd__custom-button--div">
          <div
            v-if="isTitleDisplay"
            id="course-title"
            class="d-none d-lg-block"
          >
            {{ getSelectedCourse.name }}
          </div>

          <!-- learn more -->
          <transition name="fade" mode="out-in">
            <div class="cd__course-card__btn">
              <LxpButton
                :class="{ 'gradient-bg': !isAuthenticated }"
                size="default"
                block
                @click="onLearnMore"
              >
                <span>{{ $t("partner.button.learn_more") }}</span>
                <img
                  src="@/assets/images/course-details/learn-more.svg"
                  alt="learn-more"
                />
              </LxpButton>
            </div>
          </transition>
        </div>

        <div class="cd__course-enrollment--style">
          <div class="cd__course-enrollment--div">
            <div class="cd__course-enrollment--div">
              <div class="d-none d-lg-flex">
                <div class="cd__actions--icon">
                  <img
                    src="@/assets/images/courseware/self-paced.svg"
                    alt="self placed"
                    class="cd__image--style"
                    width="20px"
                    height="18px"
                  />
                </div>
                <div class="cd__actions--text">
                  {{ pacingText }}
                </div>
              </div>
            </div>

            <div class="cd__course-enrollment--div">
              <div class="d-none d-lg-flex">
                <div class="cd__actions--icon">
                  <img
                    src="@/assets/images/courseware/translate.svg"
                    alt="translate"
                    class="cd__image--style"
                    width="20px"
                    height="18px"
                  />
                </div>
                <div class="cd__actions--text">
                  {{
                    getSelectedCourse.language
                      ? $t(`data.language.${getSelectedCourse.language}`)
                      : $t(`data.language.en`)
                  }}
                </div>
              </div>
            </div>

            <div class="cd__course-enrollment--div">
              <div class="d-none d-lg-flex">
                <div class="cd__actions--icon">
                  <img
                    src="@/assets/images/courseware/calendar.svg"
                    class="cd__image--style"
                    alt="calendar"
                    width="20px"
                    height="18px"
                  />
                </div>
                <div class="cd__actions--text">
                  <span v-if="hasFutureCourseStartDate"
                    >{{
                      $t("course.starting_on", {
                        s: getDate(getSelectedCourse.start)
                      })
                    }}
                  </span>
                  <span v-if="hasPastCourseStartDate">
                    {{
                      $t("course.started_on", {
                        s: getDate(getSelectedCourse.start)
                      })
                    }}
                  </span>
                  <p v-if="hasFutureCourseEndDate">
                    {{
                      $t("course.ending_on", {
                        s: getDate(getSelectedCourse.end)
                      })
                    }}
                  </p>
                  <span v-if="hasPastCourseEndDate">{{
                    $t("course.ended_on", {
                      s: getDate(getSelectedCourse.end)
                    })
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- share button -->
    <div class="cd__actions-share--text d-none d-lg-block">
      <div v-if="showAlert" class="alert">
        <img src="@/assets/images/courseware/copy-check.svg" alt="share" />
        {{ $t("course.copied_to_clipboard") }}
      </div>

      <div v-else class="cd__copy--text" @click="copyUrl">
        <img src="@/assets/images/courseware/share.svg" alt="share" />
        <span class="cd__actions--share">
          {{ $t("course.share") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { LxpButton } from "didactica";
// utils
import ctaActionsMixin from "./mixins/ctaActions";

export default {
  mixins: [ctaActionsMixin],
  components: { LxpButton },
  props: {
    language: {
      type: String,
      required: true
    },
    allConfig: {
      type: Object,
      required: true
    },
    isTitleDisplay: {
      type: Boolean,
      required: true
    },
    hasFutureStartDate: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showAlert: false
    };
  },
  computed: {
    ...mapGetters(["getSelectedCourse"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    hasFutureCourseStartDate() {
      return moment(new Date()).isBefore(moment(this.getSelectedCourse.start));
    },
    hasPastCourseStartDate() {
      return moment(new Date()).isAfter(moment(this.getSelectedCourse.start));
    },
    hasFutureCourseEndDate() {
      return moment(new Date()).isBefore(moment(this.getSelectedCourse.end));
    },
    hasPastCourseEndDate() {
      return moment(new Date()).isAfter(moment(this.getSelectedCourse.end));
    },
    pacingText() {
      const pacing = this.getSelectedCourse.pacing;
      if (pacing === "self" || pacing === "instructor") {
        return this.$t(`data.pacing.${pacing}`);
      } else {
        return pacing;
      }
    }
  },
  methods: {
    signUp() {
      this.$keycloak.login({
        redirectUri: this.redirectUri,
        action: "register",
        locale: this.language
      });
    },
    getDate(d) {
      return d ? moment(d).format("MMM Do, YYYY") : null;
    },
    onLearnMore() {
      window.open(this.getSelectedCourse.course_url, "_blank");
    }
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
.cd__actions-wrapper {
  .cd__action-div {
    box-shadow: 2px 5px 10px 4px rgba(0, 0, 0, 0.15);
    background: $brand-neutral-0;
    &.cd__video-url {
      border-radius: 24px 24px 10px 10px;
    }

    &.cd__video-no-url {
      border-radius: 10px;
    }

    .intro_video_url {
      border-radius: 24px 24px 0px 0px;

      img {
        position: absolute;
        top: 40%;
        left: 45%;
      }
    }
  }

  .cd__course-card {
    padding: 1rem;

    .cd__custom-button--div {
      padding-bottom: 16px;

      .cd__custom-button--style {
        padding-top: 16px;
        padding-bottom: 16px;
        border-color: transparent;
        white-space: nowrap !important;
        height: 56px !important;
        #course-title {
          padding-bottom: 16px;
          text-align: center;
          color: $brand-public-primary;
          @include button-label;
          letter-spacing: 0.15px;
        }
      }
      .cd__course-card__btn {
        .lxp-button {
          display: flex;
          padding: 12px 32px;
          img {
            margin-left: 5px;
          }
        }
      }
    }
    .cd__course-enrollment--style {
      @include label-small;
      .cd__course-enrollment--div {
        padding-bottom: 16px;
        .cd__actions--text {
          @include label-large;
          color: $brand-neutral-900;
          font-weight: 500;
          text-align: left;

          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &:last-child {
          padding-bottom: 0;
        }
      }

      .cd__image--style {
        margin-left: 10px;
      }
    }
  }

  .cd__actions-share--text {
    text-align: center;
    padding-top: 14px;

    .alert {
      @include label-large;
      text-align: center;
      letter-spacing: 0.25px;
      color: $brand-neutral-800;
      padding: 8px;
      gap: 8px;
    }

    .cd__copy--text {
      @include body-medium;
      @include flex-horizontal-center;
      color: $brand-primary-400;
      border-radius: 100px;
      cursor: pointer;

      .cd__actions--share {
        margin-left: 10px;
        align-self: center;
      }
    }
  }
}
.cd__tooltip--inner {
  @include center;

  p {
    margin-bottom: 2px;
    @include body-medium;
    color: $brand-neutral-900 !important;
  }
}

@media screen and (max-width: 991px) {
  .cd__actions-wrapper {
    & .sticky-main {
      width: 100% !important;
    }

    .cd__custom-button--div {
      padding-bottom: 0px;

      .cd__custom-button--style {
        img {
          margin-right: 10px;
        }
      }
    }

    .cd__actions--text {
      color: $brand-neutral-200;
    }
  }
}

[dir="rtl"] {
  .cd__actions-wrapper {
    #course-title {
      text-align: right;
    }

    .cd__image--style {
      margin-left: 0;
      margin-right: 10px;
    }

    .cd__actions--icon {
      padding-right: 0px;
      padding-left: 10px;
    }

    .cd__copy--text {
      .cd__actions--share {
        margin-right: 10px;
      }
    }

    .cd__custom-button--div {
      .cd__course-card__btn {
        .lxp-button {
          img {
            margin: 0 5px 0 0;
          }
        }
      }
      .cd__custom-button--style {
        img {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
