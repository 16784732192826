<template>
  <div class="course-detail__wrapper">
    <component
      :is="isAuthenticated ? 'LayoutManager' : 'LandingPageLayout'"
      class="mb-4"
    >
      <PartnerCourseDetail
        :isAuthorized="isAuthenticated"
        v-if="source === 'gateway'"
      />
      <course-detail :isAuthorized="isAuthenticated" v-else />
    </component>
  </div>
</template>
<script>
// Layouts
import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import LayoutManager from "@/components/Layout/LayoutManager";

import CourseDetail from "./CourseDetail.vue";
import PartnerCourseDetail from "./PartnerCourseDetail.vue";
export default {
  components: {
    CourseDetail,
    PartnerCourseDetail,
    LayoutManager,
    LandingPageLayout
  },
  computed: {
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    source() {
      return this.$route.query.source;
    }
  },
  created() {
    this.$store.commit("SET_SELECTED_COURSE", {});
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.commit("SET_LAYOUT_UPDATE_TRIGGERED", true);
      const postId = localStorage.getItem("postId");
      if (postId) {
        this.redirectToDiscussionPost(postId);
      } else {
        this.$store.commit("SET_LAYOUT", "UserFluidLayout");
      }
    }
  },
  methods: {
    redirectToDiscussionPost(postId) {
      localStorage.removeItem("postId");
      this.$router.push({
        path: `/self-paced/${this.$route.params.id}/forums/${postId}`
      });
      this.$store.commit("SET_LAYOUT", "main");
    }
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 990px) {
  .course-detail__wrapper {
    .lp-sec__footer {
      margin-bottom: 104px;
    }
  }
}
</style>
